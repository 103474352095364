.FooterLink {
  text-decoration: none !important;
  .AppFooter {
    border: 2px solid #343a40;
    padding: 1rem 0 0.5rem 0;
    border-radius: 0 0 2rem 2rem;
    margin-bottom: 0.4rem;
    background-color: #343a40;
    &:hover {
      background-color: #23272b; } } }
