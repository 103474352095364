.AppHeader {
  .TitleLink {
    text-decoration: none !important;
    color: #343a40;
    .Title {
      font-size: 27pt;
      padding-top: 0.01rem; } }
  .TextBox {
    border: 2px solid #343a40;
    border-radius: 4px; } }
