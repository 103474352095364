.ItemLink {
  text-decoration: none !important; }
div.SectionItem, a.SectionItem {
  border: 2px solid #343a40 !important;
  color: #343a40 !important;
  padding: 0;
  background-color: #f8f9fa !important;
  &:hover {
    background-color: #cecece !important; }
  h5 {
    margin: 0;
    padding: 0.7rem; }
  a {
    color: #343a40 !important;
    text-decoration: none !important; }
  .Icon {
    margin-right: 0.5rem; } }

