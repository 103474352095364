div.RepoBody {
  border: 3px solid #343a40;
  margin-bottom: 0.4rem;
  text-align: center;
  a.ContentLink {
    height: 100%;
    width: 100%;
    color: #343a40 !important;
    text-decoration: none !important; }
  .Body {
    padding-bottom: 0.5rem; }
  p {
    margin-bottom: 0.5rem; }
  .InfoModalToggler {
    border-radius: 100%;
    margin-bottom: 0.5rem; } }
