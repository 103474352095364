.SquareHeader {
  display: block !important;
  border: 2px solid #343a40;
  padding: 1rem 0 0.5rem 0;
  margin-bottom: 0.4rem;
  background-color: #343a40;
  position: relative; }
.RoundHeader {
  @extend .SquareHeader;
  border-radius: 2rem 2rem 0 0; }

.SquareBackButton {
  color: #F8F9FA !important;
  background-color: #343a40;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 118%;
  width: 8rem;
  top: -0.3rem;
  left: -0.3rem;
  border: 0.2rem solid #F8F9FA;
  &:hover {
    background-color: #23272b; } }
.RoundBackButton {
  @extend .SquareBackButton;
  border-radius: 2rem 0 0 0; }

